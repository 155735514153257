var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"auto","min-height":"100%","display":"flex","flex-direction":"column","overflow":"hidden"}},[_c('Input'),_c('div',{staticClass:"productsList",staticStyle:{"overflow":"auto"},style:({
			height: `${this.pageHeight - 127}px`,
			width: `${this.pageWidth}px`,
		})},[_c('div',{ref:"productsDiv",staticStyle:{"display":"flex","flex-wrap":"wrap","justify-content":"flex-start","align-content":"space-between","overflow":"auto"}},_vm._l((_vm.products || []),function(product,itemIndex){return _c('div',{key:itemIndex,staticClass:"product-div",style:({
					width: _vm.boxWidth + 'px',
					height: _vm.boxWidth + 30 + 'px',
					marginTop: '5px',
					marginBottom: '5px',
				}),on:{"click":function($event){return _vm.Search(product.id)}}},[_c('div',{staticClass:"productsImg",staticStyle:{"display":"flex","justify-content":"center"},style:({
						width: _vm.boxWidth + 'px',
						height: _vm.boxWidth - 20 + 'px',
					})},[_c('el-image',{staticStyle:{"background-size":"contain","width":"90%"},attrs:{"src":product.img}})],1),_c('div',{staticClass:"Price"},[_c('p',{staticStyle:{"font-size":"15px","margin-top":"18px","text-align":"center"}},[_vm._v(" "+_vm._s(product.title)+" ")])])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }