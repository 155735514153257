var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"auto","min-height":"100%","display":"flex","flex-direction":"column","overflow":"auto"},style:({
		height: `${this.pageHeight - 60}px`,
		width: `${this.pageWidth}px`,
	})},[_c('div',{staticClass:"guide-container-img",style:({
			backgroundImage: 'url(' + _vm.currentImgPath + ')',
			backgroundSize: '100% 100%',
			backgroundRepeat: 'no-repeat',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: 'white',
			fontSize: '24px',
		})},[_vm._v(" "+_vm._s(_vm.currentName)+" ")]),_c('div',{staticClass:"guide-container-menu"},_vm._l((_vm.menu),function(item,index){return _c('div',{key:index,class:{ active: _vm.boldStates[index] },staticStyle:{"padding":"0 10px"},on:{"click":function($event){_vm.toggleBold(index);
				_vm.scrollToFirst(index);}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"productsList",style:({
			height: `${this.pageHeight - 360}px`,
			width: `${this.pageWidth}px`,
		})},[_c('div',{ref:"productsDiv",staticStyle:{"height":"100%","width":"100%","display":"flex","flex-wrap":"wrap","justify-content":"flex-start","align-content":"space-between"}},_vm._l((_vm.products || []),function(product,itemIndex){return _c('div',{key:itemIndex,staticClass:"product-div",style:({
					width: _vm.boxWidth + 'px',
					height: _vm.boxWidth + 30 + 'px',
					marginTop: '5px',
					marginBottom: '5px',
				}),on:{"click":function($event){return _vm.GuideId(product.id)}}},[_c('div',{staticClass:"productsImg",style:({
						width: _vm.boxWidth + 'px',
						height: _vm.boxWidth - 20 + 'px',
					})},[_c('el-image',{staticStyle:{"background-size":"contain","width":"90%"},attrs:{"src":product.img,"lazy":""}})],1),_c('div',{staticClass:"Price"},[_c('p',{staticStyle:{"font-size":"15px","margin-top":"18px"}},[_vm._v(" "+_vm._s(product.title)+" ")])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }