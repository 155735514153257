<template>
	<div
		style="
			height: auto;
			min-height: 100%;
			display: flex;
			flex-direction: column;
			overflow: auto;
		"
		:style="{
			height: `${this.pageHeight - 60}px`,
			width: `${this.pageWidth}px`,
		}">
		<div
			style="
				height: 575px;
				position: relative; /* 确保子元素可以相对于此容器定位 */
			">
			<el-carousel
				ref="carousel"
				:loop="true"
				:autoplay="true"
				height="570px"
				indicator-position="none"
				@change="onCarouselChange">
				<el-carousel-item
					v-for="(item, index) in menu"
					:key="index">
					<div class="image-container">
						<img
							:src="item"
							alt="Carousel Image"
							style="
								width: 90%;
								height: 100%;
								object-fit: contain;
							" />
					</div>
				</el-carousel-item>
			</el-carousel>
			<p
				class="overlay"
				@click="JumpPages()">
				<i class="el-icon-arrow-left"></i>
			</p>
			<p class="overlay-text">
				{{ currentIndex + 1 }} / {{ currentImageCount }}
			</p>
		</div>
		<div class="commodity-name">
			<!-- 短横线 -->
			<div
				style="
					width: 100%;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
				">
				<div
					style="
						width: 50px;
						height: 2px;
						background-color: #9e9e9e;
					"></div>
			</div>
			<!-- name -->
			<div
				style="
					height: auto;
					width: calc(100% - 130px);
					padding: 0 100px 0 30px;
				">
				<p style="font-size: 20px; margin: 0">
					{{ commodity.title }}
				</p>
			</div>
		</div>
		<!-- 介绍 -->
		<div
			style="
				height: auto;
				width: calc(100% - 60px);
				padding: 0 30px 15px;
			">
			<p style="font-size: 12px">
				{{ commodity.description }}
			</p>
		</div>
		<div style="height: auto; width: 100%"></div>
		<div
			class="shoufengqing"
			style="height: auto; width: 100%">
			<el-collapse
				v-model="activeNames"
				@change="handleChange">
				<el-collapse-item
					title="商品详情"
					name="1">
					<div
						class="Sp"
						v-html="commodity.content"></div>
				</el-collapse-item>
			</el-collapse>
		</div>
	</div>
</template>

<script>
	import Hammer from 'hammerjs';
	import { mapState } from 'vuex';

	export default {
		data() {
			const menu = [];
			const PHAR = [];
			const commodity = [];
			return {
				menu,
				currentIndex: 0, // 当前显示的图片索引
				currentImageCount: 0, // 总图片数量
				CatalogNumber: 'R09195',
				PHAR,
				activeNames: ['1'],
				commodity,
				pageHeight: 0,
				pageWidth: 0,
				JumpPage: null,
			};
		},
		computed: {
			...mapState('ladiesAudition', ['commodityID', 'Pages']),
		},
		methods: {
			onCarouselChange(index) {
				// 更新当前图片索引
				this.currentIndex = index;
			},
			handleChange(val) {
				// console.log(val);
			},
			postcommodity() {
				return fetch('/index/product', {
					method: 'POST',
					headers: {
						'Content-Type':
							'application/x-www-form-urlencoded;charset=UTF-8',
					},
					body: `id=${this.commodityID}`,
				})
					.then((response) => response.json())
					.then((data) => {
						const commoditys = data.data;
						this.commodity = commoditys;
						// console.log(this.commodity);
						this.menu = commoditys.imgs;
						this.PHAR = commoditys.imgs;
						// 更新 图片总数
						this.currentImageCount = this.menu.length;
						// console.log(this.menu);
					});
			},
			// 返回
			JumpPages() {
				this.$store.commit(
					'ladiesAudition/setPages',
					`1-${this.JumpPage}`
				);
			},
		},

		mounted() {
			const carousel = this.$refs.carousel;
			const hammer = new Hammer(carousel.$el);
			this.postcommodity();
			hammer.on('swipeleft', () => {
				carousel.next();
			});
			hammer.on('swiperight', () => {
				carousel.prev();
			});
			this.$nextTick(() => {
				this.pageHeight = window.innerHeight;
				// 获取窗口宽度
				this.pageWidth = window.innerWidth;
			});
			// 保存 Pages 到 JumpPage 并将 Vuex 中的 Pages 设置为 null
			if (this.Pages) {
				this.JumpPage = this.Pages;
				this.$store.commit('ladiesAudition/setGuideFalse');
			}
		},
	};
</script>

<style scoped>
	.el-carousel__item h3 {
		position: relative;
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: white;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: white;
	}

	.image-container {
		width: 100%;
		height: 100%;
		padding: 20px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #e9e9e9;
	}

	.overlay {
		z-index: 2;
		position: absolute;
		/* 使用绝对定位 */
		top: 0;
		left: 0;
		letter-spacing: -3px;
		margin: 10px;
		/* 可以根据需要调整边距 */
		color: black;
		/* 文字颜色，可以根据需要调整 */
		font-size: 1.3rem;
		/* 文字大小，可以根据需要调整 */
	}

	.overlay-text {
		z-index: 2;
		position: absolute;
		/* 使用绝对定位 */
		bottom: 10px;
		right: 20px;
		letter-spacing: -3px;
		margin: 10px;
		/* 可以根据需要调整边距 */
		color: black;
		/* 文字颜色，可以根据需要调整 */
		font-size: 1.3rem;
		/* 文字大小，可以根据需要调整 */
	}

	.el-carousel__mask {
		height: auto;
	}

	.commodity-name {
		width: 100%;
		height: auto;
		padding-bottom: 10px;
		background-color: #ffffff;
		position: sticky;
		/* 新属性sticky */
		top: 0;
		/* 距离页面顶部距离 */
	}

	.shoufengqing >>> .el-collapse-item__header {
		padding: 0 30px !important;
	}

	.shoufengqing >>> .el-collapse-item__content {
		padding: 0 30px !important;
	}

	.Sp {
		display: flex;
		flex-direction: column;
	}

	.Sp >>> img {
		height: 100%;
		width: 100%;
	}

	.Sp >>> video {
		height: 100%;
		width: 100%;
	}
</style>
