import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const ladiesAuditionModule = {
  namespaced: true,
  state: {
    itemId: null,
    listId: null,
    guide: null,
    commodityID: null,
    newData: null, // 添加 newData 属性
    // 跳转之前的页数
    Pages:null,
  },
  mutations: {
    // 重置 guide guide 状态与 detail 通用
    setGuideFalse(state) {
      state.guide = null;
      state.Pages = null;
      // console.log('更新后的 guide 值：', state.guide);
    },
    SETITEMANDLISTID(state, payload) {
      state.itemId = payload.itemId;
      state.listId = payload.listId;
      state.guide = payload.guide; // 更新 guide 状态
    },
    COMMODITYID(state, id) {
      state.commodityID = id.commodityID;
      state.Pages = id.Pages;
      // console.log('Pages', id.Pages);
    },

    SETNEWDATA(state, newData) {
      state.newData = newData;
      state.guide = 7;
      // console.log(state.newData);
      
    },
    setPages(state, pagesValue) {
      state.Pages = pagesValue;
      // console.log('Pages', state.Pages);
  }
  },
  actions: {
    ItemidAndListid({ commit }, payload) {
      commit('SETITEMANDLISTID', payload);
    },
    commodityID({ commit }, id) {
      commit('COMMODITYID', id);
    },
    setNewDataAction({ commit }, newData) {
      commit('SETNEWDATA', newData);
    }
  },
};

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ladiesAudition: ladiesAuditionModule,
  },
});