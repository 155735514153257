var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Homeponent",style:({
		height: `${this.pageHeight - 177}px`,
		width: `${this.pageWidth}px`,
	})},[_c('div',{staticClass:"smallSelectedBox"},_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"smallSelected",style:({
				backgroundImage: `url(${item.icon})`,
			}),on:{"click":function($event){return _vm.scrollToTop(i, -20)}}},[_c('div',{staticClass:"smallTransparent",style:({
					display: _vm.selectedIndex === i ? 'none' : 'flex',
				})},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0),_c('div',{ref:"bigListContainer",staticStyle:{"overflow":"auto"},style:({
			height: `${this.pageHeight - 267}px`,
			width: `${this.pageWidth}px`,
		})},_vm._l((_vm.items),function(item,outerIndex){return _c('div',{key:outerIndex,staticClass:"bigSelectedContainer"},[_c('div',[_c('p',{staticStyle:{"font-size":"1.5em","height":"auto","padding":"0.5rem 0","word-break":"break-all","text-align":"center"}},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.banner,"fit":_vm.fitValue}})],1),_c('div',{staticStyle:{"height":"auto","width":"100%","margin-top":"10px","display":"flex","flex-direction":"column","justify-content":"space-around","align-items":"center"}},[_c('div',{staticStyle:{"height":"auto","width":"100%","display":"flex","justify-content":"flex-start","flex-wrap":"wrap"}},[_c('div',{staticStyle:{"height":"auto","width":"96%","margin":"0 auto","display":"flex","flex-wrap":"wrap"}},_vm._l((item.product || []),function(innerItem,innerIndex){return _c('div',{key:innerIndex,staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","width":"33.3%","box-sizing":"border-box"},on:{"click":function($event){return _vm.handleCommodityClick(innerItem.id)}}},[_c('div',{staticStyle:{"height":"30vw","width":"30vw","border":"none","background-color":"white","outline":"none"}},[_c('el-image',{staticStyle:{"height":"100%","width":"100%","background-size":"cover","background-repeat":"no-repeat","object-fit":"contain","outline":"none","border":"0"},attrs:{"src":innerItem.img}})],1),_c('p',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(innerItem.title)+" ")])])}),0)]),_c('el-button',{staticClass:"custom-button",staticStyle:{"margin":"26px 0","display":"flex","justify-content":"center","height":"45px","align-items":"center","width":"160px","color":"black"},attrs:{"round":"","id":item.id},on:{"click":function($event){return _vm.HomeponentID(item.id)}}},[_vm._v(" 查看全部"),_c('span',{staticClass:"el-icon-arrow-right",staticStyle:{"margin-left":"5px"}})])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }