<template>
	<div
		style="
			height: auto;
			min-height: 100%;
			display: flex;
			flex-direction: column;
		"
		:style="{
			height: `${this.pageHeight - 60}px`,
			width: `${this.pageWidth}px`,
		}">
		<div
			class="guide-container-img"
			:style="{
				backgroundImage: `url(${banner})`,
				backgroundSize: '100% 100%',
				backgroundRepeat: 'no-repeat',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				color: 'white',
				fontSize: '24px',
			}">
			客服中心
		</div>
		<div
			style="padding: 20px"
			class="content"
			:style="{
				height: `${this.pageHeight - 310}px`,
			}"
			v-html="content"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				content: null,
				banner: null,
				pageHeight: 0,
				pageWidth: 0,
			};
		},
		methods: {
			sendPostRequest() {
				fetch('/index/about', {
					method: 'GET',
				})
					.then((response) => response.json())
					.then((data) => {
						this.content = data.content;
						this.banner = data.banner;
						return this.content;
					})
					.catch((error) => console.error(error));
			},
		},
		mounted() {
			this.pageHeight = window.innerHeight;
			// 获取窗口宽度
			this.pageWidth = window.innerWidth;
			this.sendPostRequest();
		},
	};
</script>
<style scoped>
	.guide-container-menu {
		height: 50px;
		width: calc(100% - 20px);
		display: flex;
		flex-direction: row;
		overflow: auto;
		padding-left: 20px;
		background-color: white;
		z-index: 2;
		position: sticky;
		/* 新属性sticky */
		top: 0;
		/* 距离页面顶部距离 */
	}

	.guide-container-img {
		height: 250px;
		width: 100%;
		/* background-size: contain; 保持图片的原始比例并使其完全适合容器 */
	}

	.guide-container-menu div {
		white-space: nowrap;
		font-weight: normal;
		display: flex;
		align-items: center;
		font-size: 13px;
	}

	.guide-container-menu div.active {
		font-weight: bold;
	}

	.guide-container-menu::-webkit-scrollbar {
		display: none;
	}

	.productsList {
		width: 100%;
		height: auto;
	}

	.productsImg {
		/* background-image: url('../assets/lv.png'); */
		display: flex;
		align-items: flex-end;
		justify-content: center;
		background-repeat: no-repeat;
		border-radius: 0px;
		background: #f2f2f2;
		box-shadow: inset 50px 50px 100px #e8e8e8,
			inset -50px -50px 100px #fcfcfc;
	}

	.product-div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Price {
		width: 100%;
		margin: 0;
		text-align: center;
		height: 30%;
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-evenly;
		background: rgb(217, 212, 212);
		background: linear-gradient(
			180deg,
			rgba(217, 212, 212, 1) 0%,
			rgba(255, 255, 255, 1) 1%
		);
	}

	.content >>> p {
		font-size: 15px !important;
	}
</style>
