<template>
	<div
		style="
			height: auto;
			min-height: 100%;
			display: flex;
			flex-direction: column;
			overflow: hidden;
		">
		<Input></Input>
		<div
			class="productsList"
			:style="{
				height: `${this.pageHeight - 127}px`,
				width: `${this.pageWidth}px`,
			}"
			style="overflow: auto">
			<div
				ref="productsDiv"
				style="
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-content: space-between;
					overflow: auto;
				">
				<div
					class="product-div"
					v-for="(product, itemIndex) in products || []"
					:key="itemIndex"
					@click="Search(product.id)"
					:style="{
						width: boxWidth + 'px',
						height: boxWidth + 30 + 'px',
						marginTop: '5px',
						marginBottom: '5px',
					}">
					<div
						class="productsImg"
						style="display: flex; justify-content: center"
						:style="{
							width: boxWidth + 'px',
							height: boxWidth - 20 + 'px',
						}">
						<el-image
							:src="product.img"
							style="
								background-size: contain;
								width: 90%;
							"></el-image>
					</div>
					<div class="Price">
						<p
							style="
								font-size: 15px;
								margin-top: 18px;
								text-align: center;
							">
							{{ product.title }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Input from '../components/input.vue';
	import { mapState, mapActions } from 'vuex';

	export default {
		components: {
			Input,
		},
		data() {
			return {
				count: 0,
				parentWidth: 0,
				itemIndex: 0,
				pageHeight: 0,
				pageWidth: 0,
			};
		},
		created() {
			this.$store.commit('ladiesAudition/setGuideFalse');
		},
		computed: {
			...mapState('ladiesAudition', ['itemId', 'listId', 'newData']),
			boxWidth() {
				return this.parentWidth / 2; // 减去左右间距
			},
			products() {
				return this.newData || [];
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.pageHeight = window.innerHeight;
				// 获取窗口宽度
				this.pageWidth = window.innerWidth;
				this.updateParentWidth();
			});
			window.addEventListener('resize', this.updateParentWidth);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.updateParentWidth);
		},
		methods: {
			updateParentWidth() {
				if (this.$refs.productsDiv) {
					// 使用 requestAnimationFrame 来确保 DOM 更新已完成
					requestAnimationFrame(() => {
						this.parentWidth = this.$refs.productsDiv.clientWidth;
						this.pageHeight = window.innerHeight;
						// 获取窗口宽度
						this.pageWidth = window.innerWidth;
					});
				}
			},
			// 跳转商品详情
			...mapActions('ladiesAudition', ['commodityID']),
			Search(id) {
				const Pages = 7;
				this.commodityID({ commodityID: id, Pages: Pages });
			},
		},
	};
</script>
<style scoped>
	.productsList {
		width: 100%;
	}

	.productsImg {
		/* background-image: url('../assets/lv.png'); */
		display: flex;
		align-items: flex-end;
		justify-content: center;
		background-repeat: no-repeat;
		border-radius: 0px;
		background: #f2f2f2;
		box-shadow: inset 50px 50px 100px #e8e8e8,
			inset -50px -50px 100px #fcfcfc;
	}

	.product-div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Price {
		width: 100%;
		margin: 0;
		text-align: center;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-evenly;
		background: rgb(217, 212, 212);
		background: linear-gradient(
			180deg,
			rgba(217, 212, 212, 1) 0%,
			rgba(255, 255, 255, 1) 1%
		);
	}
</style>
