var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Input'),_c('div',{staticStyle:{"background-color":"white"},style:({
			height: `${this.pageHeight - 127}px`,
			width: `${this.pageWidth}px`,
		})},[_c('div',{staticClass:"tabs-nav",style:({
				height: '40px',
				width: `${this.pageWidth - 40}px`,
				overflowX: 'auto',
			})},[_c('i',{staticClass:"el-icon-arrow-left",staticStyle:{"padding-top":"3px"}}),_vm._l((_vm.titleData),function(item,index){return _c('div',{key:index,staticClass:"tab-item",class:{ 'is-active': _vm.activeName === 'tab' + index },staticStyle:{"white-space":"nowrap","display":"inline-block"},on:{"click":function($event){return _vm.handleClick(index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2),_c('Homeomponent',{key:_vm.selectedItem ? _vm.selectedItem.id : null,attrs:{"tab-data":_vm.selectedItem ? _vm.selectedItem : {}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }