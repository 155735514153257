<template>
	<div
		id="app"
		:style="{
			height: `${this.pageHeight}px`,
			width: `${this.pageWidth}px`,
		}"
		style="position: relative">
		<!-- 视图 -->
		<div
			:style="{
				height: `${this.pageHeight - 60}px`,
				width: `${this.pageWidth}px`,
			}">
			<component :is="currentComponent"></component>
		</div>
		<!-- 导航菜单 -->
		<div
			:style="{
				width: `${this.pageWidth}px`,
			}"
			style="height: 60px; bottom: 0; position: fixed">
			<el-menu
				:default-active="activeIndex"
				class="el-menu-demo"
				mode="horizontal"
				@select="handleSelect">
				<el-menu-item index="1">
					<i class="el-icon-s-home">
						<p>首页</p>
					</i>
				</el-menu-item>
				<el-menu-item index="2">
					<i class="el-icon-film">
						<p>发现</p>
					</i>
				</el-menu-item>
				<el-menu-item index="3">
					<i class="el-icon-s-claim">
						<p>甄礼指南</p>
					</i>
				</el-menu-item>

				<el-menu-item index="4">
					<i class="el-icon-full-screen">
						<p>定制</p>
					</i>
				</el-menu-item>
				<el-menu-item index="5">
					<i class="el-icon-service">
						<p>客服</p>
					</i>
				</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>

<script>
	import Home from '../src/views/Home.vue';
	import Discovery from '../src/views/Discovery.vue';
	import Guide from '../src/views/Guide.vue';
	import customize from './views/customize.vue';
	import concerning from './views/concerning.vue';
	import detail from './views/detail.vue';
	import SearchInterface from './views/SearchInterface.vue';
	import { mapState } from 'vuex';
	export default {
		name: 'App',
		data() {
			return {
				activeIndex: '1',
				components: {
					1: Home,
					2: Discovery,
					3: Guide,
					4: customize,
					5: concerning,
					6: detail,
					7: SearchInterface,
				},
				pageHeight: 0,
				pageWidth: 0,
			};
		},
		computed: {
			...mapState('ladiesAudition', ['guide', 'Pages']),
			currentComponent() {
				return this.components[this.activeIndex] || Home;
			},
		},
		components: {
			Home,
			Discovery,
			Guide,
			detail,
			customize,
			concerning,
			SearchInterface,
		},
		methods: {
			handleSelect(index) {
				this.activeIndex = index;
			},
		},
		watch: {
			guide(newVal) {
				if (newVal) {
					// 如果当前不是甄礼指南和详细页面，则进行跳转
					if (newVal === 1 || newVal === 2) {
						this.activeIndex = '3';
					} else if (newVal === 7) {
						this.activeIndex = '7';
					}
				}
			},
			Pages(val) {
				if (val === '1-1') {
					this.activeIndex = '1';
				} else if (val === '1-3') {
					this.activeIndex = '3';
				} else if (val === '1-4') {
					this.activeIndex = '4';
				} else if (val === '1-7') {
					this.activeIndex = '7';
				} else if (val) {
					this.activeIndex = '6';
				}
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.pageHeight = window.innerHeight;
				document.body.style.height = this.pageHeight + 'px';
				// 获取窗口宽度
				this.pageWidth = window.innerWidth;
				document.body.style.width = this.pageWidth + 'px';
				// console.log(this.pageHeight, this.pageWidth);
			});
		},
	};
</script>

<style>
	.el-menu-demo {
		height: 60px !important;
		margin: 0 !important;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.el-menu-demo p {
		margin: 0 !important;
		font-size: 11px;
		text-align: center;
	}
	.el-menu::after,
	.el-menu::before {
		display: none !important;
	}
	.el-menu.el-menu--horizontal {
		border: none !important;
		border: none !important;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-evenly;
		background-color: white;
	}

	li {
		height: 39px !important;
	}
	.el-dropdown-menu__item,
	.el-menu-item {
		padding: 0 !important;
	}
	.el-menu-demo i {
		display: flex !important;
		flex-direction: column;
		align-items: center;
		font-size: 25px !important;
	}

	.el-menu-demo .is-active {
		border-bottom: none !important;
	}

	.el-menu-demo .el-menu-item {
		border-bottom: none !important;
	}
</style>
