<template>
	<div>
		<Input></Input>
		<div
			style="background-color: white"
			:style="{
				height: `${this.pageHeight - 127}px`,
				width: `${this.pageWidth}px`,
			}">
			<div
				class="tabs-nav"
				:style="{
					height: '40px',
					width: `${this.pageWidth - 40}px`,
					overflowX: 'auto',
				}">
				<i
					class="el-icon-arrow-left"
					style="padding-top: 3px"></i>
				<div
					v-for="(item, index) in titleData"
					:key="index"
					class="tab-item"
					style="white-space: nowrap; display: inline-block"
					:class="{ 'is-active': activeName === 'tab' + index }"
					@click="handleClick(index)">
					{{ item.title }}
				</div>
			</div>
			<!-- 根据 selectedItem 动态传递 tab-data -->
			<Homeomponent
				:tab-data="selectedItem ? selectedItem : {}"
				:key="selectedItem ? selectedItem.id : null"></Homeomponent>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import Homeomponent from '../components/Homeponent.vue';
	import Input from '../components/input.vue';
	import { set } from 'vue';

	export default {
		components: {
			Homeomponent,
			Input,
		},
		data() {
			return {
				input: '',
				activeName: 'tab0',
				titleData: null,
				pageHeight: 0,
				pageWidth: 0,
				// 滚动的位置动画
				isScrolling: false,
				// 传递的数据
				selectedItem: null,
			};
		},
		created() {
			this.sendPostRequest();
		},
		methods: {
			handleClick(index) {
				this.activeName = 'tab' + index;
				this.scrollToFirst(index);
				// 使用 Vue.set 更新 selectedItem
				set(this, 'selectedItem', this.titleData[index]);
				// console.log('Selected Item:', this.selectedItem);
			},
			async sendPostRequest() {
				try {
					const response = await axios.get('/index/home', {
						data: {},
					});
					const dataWithId = response.data.data.map((item) => ({
						title: item.title,
						id: item.id,
					}));
					this.titleData = dataWithId;
					// 在组件创建时，如果 titleData 有数据，默认设置 selectedItem 为第一个 item
					if (this.titleData.length > 0) {
						set(this, 'selectedItem', this.titleData[0]);
						// console.log(
						// 	'Initial Selected Item:',
						// 	this.selectedItem
						// );
					}
					return this.titleData;
				} catch (error) {
					// console.error(error);
					return null;
				}
			},
			scrollToFirst(index) {
				if (this.isScrolling) return;
				this.isScrolling = true;
				const menuContainer = this.$el.querySelector('.tabs-nav');
				const selectedItem =
					menuContainer.querySelectorAll('div')[index];
				const startScrollLeft = menuContainer.scrollLeft;
				const distance = selectedItem.offsetLeft - startScrollLeft - 66;
				const duration = 500;
				let startTime = null;
				const animateScroll = (timestamp) => {
					if (!startTime) startTime = timestamp;
					const elapsed = timestamp - startTime;
					const progress = Math.min(elapsed / duration, 1);
					menuContainer.scrollLeft =
						startScrollLeft + distance * progress;
					if (elapsed < duration) {
						requestAnimationFrame(animateScroll);
					} else {
						this.isScrolling = false;
					}
				};
				requestAnimationFrame(animateScroll);
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.pageHeight = window.innerHeight;
				document.body.style.height = this.pageHeight + 'px';
				// 获取窗口宽度
				this.pageWidth = window.innerWidth;
				document.body.style.width = this.pageWidth + 'px';
				// console.log(this.pageHeight, this.pageWidth);
			});
		},
	};
</script>
<style>
	.tabs-nav {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		overflow: scroll;
		align-items: center;
		padding: 0 20px 10px;
	}

	.tabs-nav::-webkit-scrollbar {
		display: none;
	}

	.tab-item {
		margin: 0 20px 0 30px;
		height: 30px;
		position: relative;
	}

	.tab-item::after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 0;
		height: 2px;
		background-color: black;
		transition: width 0.3s ease, left 0.3s ease;
	}

	.is-active::after {
		width: 100%;
	}
</style>
