<template>
	<div class="input-container">
		<!-- LOGO -->
		<div
			:style="{
				backgroundImage: `url(${Img})`,
			}"
			style="
				max-width: 100%;
				max-height: 100%;
				width: 11%;
				height: 35px;
				background-size: contain;
				background-repeat: no-repeat;
			"></div>
		<div style="width: 70vw">
			<el-input
				size="mini"
				:style="{ outline: 'none', boxShadow: 'none' }"
				placeholder="请输入内容"
				v-model="input"
				@keydown.enter.native="search">
				<el-button
					@click="search"
					slot="append"
					icon="el-icon-search"></el-button>
			</el-input>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import { mapActions } from 'vuex';

	export default {
		data() {
			return {
				input: '',
				Img: null,
			};
		},
		created() {
			this.sendPostRequest();
		},
		methods: {
			...mapActions({
				setNewDataAction: 'ladiesAudition/setNewDataAction',
			}),
			async sendPostRequest() {
				try {
					const response = await axios.post('/index/home', {
						data: {},
					});
					const Img = response.data.logo;
					this.Img = Img;
					return this.Img;
				} catch (error) {
					return null;
				}
			},
			async search() {
				if (!this.input) {
					this.$notify({
						title: '请输入商品名称',
						offset: 100,
						duration: 1000,
					});
					return;
				}
				try {
					const response = await axios.get('/index/search', {
						params: { text: this.input },
					});
					if (response.data.count === 0) {
						this.$notify({
							title: '无此商品',
							offset: 100,
							duration: 1000,
						});
					} else {
						const data = response.data.data;
						this.setNewDataAction(data);
					}
				} catch (error) {
					console.error(error);
				}
			},
		},
	};
</script>

<style scoped>
	.input-container {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 16px;
		/* 新属性sticky */
		position: sticky !important;
		top: 0;
		z-index: 200;
		background-color: #ffffff;
	}
	.input-container >>> .el-input__inner {
		border-radius: 20px 0 0 20px !important;
		border-right: 0;
	}

	.input-container >>> .el-input-group__append,
	.el-input-group__prepend {
		background-color: transparent !important;
		border-top-right-radius: 20px !important;
		border-bottom-right-radius: 20px !important;
	}

	.input-container >>> .el-input__inner:focus {
		border-color: initial; /*设置为透明  */
		box-shadow: none;
		border: 1px solid #dcdfe6 !important;
		outline: none !important; /* 移除聚焦时的外边框 */
		box-shadow: none !important; /* 如果有阴影的话也移除 */
	}
</style>
