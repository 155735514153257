import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=cb95e436&scoped=true"
import script from "./Selection.vue?vue&type=script&lang=js"
export * from "./Selection.vue?vue&type=script&lang=js"
import style0 from "./Selection.vue?vue&type=style&index=0&id=cb95e436&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb95e436",
  null
  
)

export default component.exports