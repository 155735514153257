var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"},style:({
		height: `${this.pageHeight}px`,
		width: `${this.pageWidth}px`,
	}),attrs:{"id":"app"}},[_c('div',{style:({
			height: `${this.pageHeight - 60}px`,
			width: `${this.pageWidth}px`,
		})},[_c(_vm.currentComponent,{tag:"component"})],1),_c('div',{staticStyle:{"height":"60px","bottom":"0","position":"fixed"},style:({
			width: `${this.pageWidth}px`,
		})},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal"},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"1"}},[_c('i',{staticClass:"el-icon-s-home"},[_c('p',[_vm._v("首页")])])]),_c('el-menu-item',{attrs:{"index":"2"}},[_c('i',{staticClass:"el-icon-film"},[_c('p',[_vm._v("发现")])])]),_c('el-menu-item',{attrs:{"index":"3"}},[_c('i',{staticClass:"el-icon-s-claim"},[_c('p',[_vm._v("甄礼指南")])])]),_c('el-menu-item',{attrs:{"index":"4"}},[_c('i',{staticClass:"el-icon-full-screen"},[_c('p',[_vm._v("定制")])])]),_c('el-menu-item',{attrs:{"index":"5"}},[_c('i',{staticClass:"el-icon-service"},[_c('p',[_vm._v("客服")])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }