<template>
	<div
		class="Homeponent"
		:style="{
			height: `${this.pageHeight - 177}px`,
			width: `${this.pageWidth}px`,
		}">
		<!-- 小图标 -->
		<div class="smallSelectedBox">
			<div
				v-for="(item, i) in items"
				:key="i"
				class="smallSelected"
				:style="{
					backgroundImage: `url(${item.icon})`,
				}"
				@click="scrollToTop(i, -20)">
				<div
					class="smallTransparent"
					:style="{
						display: selectedIndex === i ? 'none' : 'flex',
					}">
					{{ item.title }}
				</div>
			</div>
		</div>
		<!-- 大列表 -->
		<div
			ref="bigListContainer"
			style="overflow: auto"
			:style="{
				height: `${this.pageHeight - 267}px`,
				width: `${this.pageWidth}px`,
			}">
			<!-- 整体容器 -->
			<div
				class="bigSelectedContainer"
				v-for="(item, outerIndex) in items"
				:key="outerIndex">
				<div>
					<p
						style="
							font-size: 1.5em;
							height: auto;
							padding: 0.5rem 0;
							word-break: break-all;
							text-align: center;
						">
						{{ item.title }}
					</p>
				</div>
				<div>
					<el-image
						style="width: 100%; height: 100%"
						:src="item.banner"
						:fit="fitValue">
					</el-image>
				</div>
				<div
					style="
						height: auto;
						width: 100%;
						margin-top: 10px;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						align-items: center;
					">
					<div
						style="
							height: auto;
							width: 100%;
							display: flex;
							justify-content: flex-start;
							flex-wrap: wrap;
						">
						<div
							style="
								height: auto;
								width: 96%;
								margin: 0 auto;
								display: flex;
								flex-wrap: wrap;
							">
							<div
								v-for="(
									innerItem, innerIndex
								) in item.product || []"
								:key="innerIndex"
								@click="handleCommodityClick(innerItem.id)"
								style="
									display: flex;
									flex-direction: column;
									align-items: center;
									width: 33.3%;
									box-sizing: border-box;
								">
								<div
									style="
										height: 30vw;
										width: 30vw;
										border: none;
										background-color: white;
										outline: none;
									">
									<el-image
										:src="innerItem.img"
										style="
											height: 100%;
											width: 100%;
											background-size: cover;
											background-repeat: no-repeat;
											object-fit: contain;
											outline: none;
											border: none;
											border: 0;
										"></el-image>
								</div>
								<p class="text-ellipsis">
									{{ innerItem.title }}
								</p>
								<!-- <p
								style="
									margin: 5px;
									font-size: 10px;
									color: #9d9d9d;
								">
								$200
							</p> -->
							</div>
						</div>
					</div>
					<el-button
						round
						style="
							margin: 26px 0;
							display: flex;
							justify-content: center;
							height: 45px;
							align-items: center;
							width: 160px;
							color: black;
						"
						:id="item.id"
						@click="HomeponentID(item.id)"
						class="custom-button">
						查看全部<span
							style="margin-left: 5px"
							class="el-icon-arrow-right"></span>
					</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import { mapActions } from 'vuex';

	export default {
		name: 'Homeponent',
		props: ['tabData'],
		data() {
			return {
				items: [],
				pageHeight: 0,
				pageWidth: 0,
				fitValue: 'cover',
				selectedIndex: 0,
				isScrolling: false,
			};
		},
		created() {
			// 在组件创建时将 guide 设置为 null
			this.$store.commit('ladiesAudition/setGuideFalse');
			this.sendPostRequest();
		},
		methods: {
			scrollToTop(index, leftAdjustment) {
				this.isScrolling = true;

				const smallSelectedBox =
					this.$el.querySelector('.smallSelectedBox');
				const smallSelected =
					smallSelectedBox.querySelectorAll('.smallSelected')[index];
				const startScrollLeft = smallSelectedBox.scrollLeft;
				const distance =
					smallSelected.offsetLeft - startScrollLeft + leftAdjustment;
				const duration = 500;
				let startTime = null;

				const animateScroll = (timestamp) => {
					if (!startTime) startTime = timestamp;
					const elapsed = timestamp - startTime;
					const progress = Math.min(elapsed / duration, 1);
					smallSelectedBox.scrollLeft =
						startScrollLeft + distance * progress;
					if (elapsed < duration) {
						requestAnimationFrame(animateScroll);
					} else {
						this.isScrolling = false;
					}
				};
				requestAnimationFrame(animateScroll);

				const selectedContainer = this.$refs.bigListContainer;
				const bigSelectedContainer = selectedContainer.querySelectorAll(
					'.bigSelectedContainer'
				)[index];
				const targetScrollTop =
					bigSelectedContainer.offsetTop -
					selectedContainer.offsetTop -
					30;

				let currentScrollTop = selectedContainer.scrollTop;
				startTime = null;

				const animateBigScroll = (timestamp) => {
					if (!startTime) startTime = timestamp;
					const elapsed = timestamp - startTime;
					const progress = Math.min(elapsed / duration, 1);
					selectedContainer.scrollTop =
						currentScrollTop +
						(targetScrollTop - currentScrollTop) * progress;
					if (elapsed < duration) {
						requestAnimationFrame(animateBigScroll);
					} else {
						this.isScrolling = false;
					}
				};
				requestAnimationFrame(animateBigScroll);

				this.selectedIndex = index;
			},
			handleBigSelectedScroll(event) {
				if (this.isScrolling) return;
				const selectedContainer = this.$refs.bigListContainer;
				const bigSelectedElements = selectedContainer.querySelectorAll(
					'.bigSelectedContainer'
				);
				const containerScrollTop = selectedContainer.scrollTop;

				let visibleTopIndex = null;
				for (let j = 0; j < bigSelectedElements.length; j++) {
					const bigSelected = bigSelectedElements[j];
					const elementTop =
						bigSelected.offsetTop - selectedContainer.offsetTop;
					// 检查元素是否进入可视区域
					if (
						elementTop >= containerScrollTop &&
						(visibleTopIndex === null ||
							elementTop <
								bigSelectedElements[visibleTopIndex].offsetTop)
					) {
						visibleTopIndex = j;
					}
				}

				// 如果找到了新的可见顶部索引，则更新 selectedIndex 并滚动小图标
				if (
					visibleTopIndex !== null &&
					this.selectedIndex !== visibleTopIndex
				) {
					this.selectedIndex = visibleTopIndex;
					this.scrollSmallSelectedToTop(visibleTopIndex);
				}
			},
			scrollSmallSelectedToTop(index) {
				const smallSelectedBox =
					this.$el.querySelector('.smallSelectedBox');
				const smallSelected =
					smallSelectedBox.querySelectorAll('.smallSelected')[index];
				const startScrollLeft = smallSelectedBox.scrollLeft;
				const distance =
					smallSelected.offsetLeft - startScrollLeft - 20;
				const duration = 500;
				let startTime = null;

				const animateScroll = (timestamp) => {
					if (!startTime) startTime = timestamp;
					const elapsed = timestamp - startTime;
					const progress = Math.min(elapsed / duration, 1);
					smallSelectedBox.scrollLeft =
						startScrollLeft + distance * progress;
					if (elapsed < duration) {
						requestAnimationFrame(animateScroll);
					}
				};
				requestAnimationFrame(animateScroll);

				this.selectedIndex = index;
			},
			async sendPostRequest() {
				try {
					const response = await axios.get('/index/home', {
						data: {},
					});
					const titles = response.data.data;
					document.title = response.data.sitename;
					const foundItem = titles.find(
						(item) => item.title === this.tabData.title
					);
					if (foundItem) {
						this.items = foundItem.child;
						// console.log(this.items);

						this.$nextTick(() => {});
					}
				} catch (error) {
					console.error(error);
				}
			},
			...mapActions('ladiesAudition', ['ItemidAndListid', 'commodityID']),
			handleCommodityClick(id) {
				const Pages = 1;
				this.commodityID({ commodityID: id, Pages: Pages });
			},
			HomeponentID(itemid, Guide = 1) {
				const itemId = itemid;
				const listId = this.tabData.id;
				// console.log(itemId, listId);
				this.ItemidAndListid({ itemId, listId, guide: Guide });
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.pageHeight = window.innerHeight;
				document.body.style.height = this.pageHeight + 'px';
				// 获取窗口宽度
				this.pageWidth = window.innerWidth;
				document.body.style.width = this.pageWidth + 'px';

				// 绑定滚动事件监听器
				const bigListContainer = this.$refs.bigListContainer;
				if (bigListContainer) {
					bigListContainer.addEventListener(
						'scroll',
						this.handleBigSelectedScroll
					);
				}
			});
		},
		beforeDestroy() {
			// 清理事件监听器
			const bigListContainer = this.$refs.bigListContainer;
			if (bigListContainer) {
				bigListContainer.removeEventListener(
					'scroll',
					this.handleBigSelectedScroll
				);
			}
		},
	};
</script>

<style scoped>
	.Homeponent {
		display: flex;
		flex-direction: column;
	}

	.smallSelectedBox {
		height: 90px;
		display: flex;
		align-items: center;
		overflow-x: scroll;
		background-color: white;
	}

	.smallSelectedBox::-webkit-scrollbar {
		display: none;
	}

	.smallSelected {
		height: 70px;
		width: 70px;
		min-width: 70px;
		margin-left: 15px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		border-radius: 5px;
	}

	.smallTransparent {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 0.66em;
		border-radius: 5px;
	}

	.custom-button.el-icon-arrow-right {
		align-self: center;
	}

	.el-button.is-round {
		border-radius: 25px;
	}

	.text-ellipsis {
		margin-top: 10px;
		font-size: 11px;
		width: 100%;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
