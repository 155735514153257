<template>
	<div>
		<Input></Input>
		<Selection></Selection>
	</div>
</template>
<script>
	import Input from '../components/input.vue';
	import Selection from '../components/Selection.vue';
	import Guide from './Guide.vue';
	export default {
		components: {
			Input,
			Selection,
			Guide,
		},
		data() {
			return {
				input: '',
				activeName: 'first',
			};
		},
		methods: {
			handleClick(tab) {
				// console.log(tab);
			},
		},
	};
</script>
<style></style>
