<template>
	<div
		class="ladies-audition"
		:style="{
			height: `${this.pageHeight - 127}px`,
			width: `${this.pageWidth - 20}px`,
		}">
		<div
			class="ladies-audition-img"
			:style="{ backgroundImage: activeBgImage }">
			{{ activeTitle }}
			<svg
				t="1729926575767"
				class="icon"
				viewBox="0 0 1024 1024"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				p-id="9900"
				width="32"
				height="32">
				<path
					d="M523.477333 691.925333l180.992-180.992-180.992-181.077333a42.666667 42.666667 0 1 1 60.330667-60.330667l211.2 211.2c6.4 6.4 10.325333 14.336 11.818667 22.613334l0.597333 5.034666v5.034667a42.496 42.496 0 0 1-12.416 27.648l-211.2 211.2a42.666667 42.666667 0 0 1-60.330667-60.330667z m-298.666666 0l180.992-180.992L224.810667 329.813333a42.666667 42.666667 0 1 1 60.330666-60.330666l211.2 211.2c6.4 6.4 10.325333 14.336 11.818667 22.613333l0.597333 5.034667v5.034666a42.496 42.496 0 0 1-12.416 27.648l-211.2 211.2a42.666667 42.666667 0 0 1-60.330666-60.330666z"
					fill="#ffffff"
					p-id="9901"></path>
			</svg>
		</div>
		<div>
			<el-tabs
				:tab-position="tabPosition"
				:style="{
					height: `${this.pageHeight - 307}px`,
					width: `${this.pageWidth - 20}px`,
				}"
				@tab-click="onTabClick">
				<el-tab-pane
					v-for="(list, listIndex) in lists"
					:key="list.id"
					:label="list.title">
					<div
						ref="parentDiv"
						class="parent-div"
						style="
							height: calc(100% - 6px);
							width: 100%;
							display: flex;
							flex-wrap: wrap;
							align-content: flex-start;
							justify-content: space-around;
							margin-top: 6px;
						">
						<div
							v-for="(innerItem, innerIndex) in list.child || []"
							:key="innerIndex"
							@click="SelectionID(innerItem, listIndex)"
							style="width: 40%; height: 36%; padding: 5px 10px">
							<div
								class="commodityImg"
								:style="{
									backgroundImage: `url(${innerItem.icon})`,
								}"></div>
							<p
								style="
									margin: 5px;
									text-align: center;
									font-size: 11px;
								">
								{{ innerItem.title }}
							</p>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import { mapActions } from 'vuex';

	export default {
		props: ['labels'],
		name: 'LadiesAuditionCopy',
		data() {
			return {
				tabPosition: 'left',
				lists: [],
				activeTitle: '',
				pageHeight: 0,
				pageWidth: 0,
				activeBgImage: '',
			};
		},
		computed: {},
		created() {
			// 在组件创建时将 guide 设置为 null
			this.$store.commit('ladiesAudition/setGuideFalse');
			this.sendPostRequest();
		},
		methods: {
			updateParentWidth() {
				if (this.$refs.parentDiv) {
					this.parentWidth = this.$refs.parentDiv.clientWidth;
				}
			},
			async sendPostRequest() {
				try {
					const response = await axios.post('/index/category', {
						data: {
							labels: this.labels,
						},
					});
					const data = response.data.data;
					this.lists = data;

					// 设置默认标题
					if (this.lists.length > 0) {
						this.activeTitle = this.lists[0].title;
					}
				} catch (error) {
					console.error(error);
				}
			},
			onTabClick(tab) {
				const list = this.lists[tab.index];
				this.activeTitle = list.title;
				if (list.banner) {
					this.activeBgImage = `url(${list.banner})`;
				} else {
					this.activeBgImage = '';
				}
			},
			...mapActions('ladiesAudition', ['ItemidAndListid']),
			SelectionID(innerItem, listIndex, Guide = 2) {
				const itemId = innerItem.id;
				const listId = this.lists[listIndex].id;
				this.ItemidAndListid({ itemId, listId, guide: Guide });
			},
			updatePageHeight() {
				this.pageHeight = window.innerHeight;
				this.pageWidth = window.innerWidth;
				document.documentElement.style.setProperty(
					'--tab-pane-height',
					`${this.pageHeight - 307}px`
				);
			},
		},
		mounted() {
			this.updatePageHeight(); // 初始化时设置页面高度
			window.addEventListener('resize', this.updatePageHeight); // 监听窗口大小变化
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.updatePageHeight); // 清理事件监听器
		},
		watch: {
			// 默认点击第一个
			lists(newValue) {
				if (newValue.length > 0) {
					this.onTabClick({ index: 0 });
				}
			},
		},
	};
</script>
<style scoped>
	.ladies-audition {
		padding: 0 10px;
	}
	.ladies-audition-img {
		height: 180px;
		width: 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: 2rem;
	}
	.commodityImg {
		width: 100%;
		height: 75%;
		background-size: contain; /* 保持图片的原始比例并使其完全适合容器 */
		background-position: center; /* 将背景图像居中 */
		background-repeat: no-repeat;
	}
	.ladies-audition >>> .el-tabs__item {
		height: 40px !important;
		width: 80px !important;
		display: flex !important;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	.ladies-audition >>> .el-tabs__nav {
		border-left: 1px solid rgb(228, 228, 228);
		border-right: 1px solid rgb(228, 228, 228);
		overflow-y: auto;
	}

	.ladies-audition >>> .el-tabs--left.el-tabs__active-bar.is-left,
	.el-tabs--left.el-tabs__active-bar.is-right,
	.el-tabs--right.el-tabs__active-bar.is-left,
	.el-tabs--right.el-tabs__active-bar.is-right {
		height: 20px !important;
		margin-top: 5px;
		display: none;
	}

	.ladies-audition >>> .el-tabs--left {
		border-right: 1px solid rgb(228, 228, 228);
	}

	.ladies-audition >>> .el-tabs--left.el-tabs__header.is-left {
		margin-right: 5px !important;
	}

	.ladies-audition >>> .el-tab-pane {
		width: calc(100vw - 112px);
		height: var(--tab-pane-height, calc(100vh - 100px)); /* 使用 CSS 变量 */
		/* background-color: black; */
		overflow: auto;
	}

	.ladies-audition >>> .el-tabs__active-bar.is-left {
		display: none !important;
	}

	.ladies-audition >>> .el-tabs__item.is-active {
		font-size: 15px !important;
		color: black !important;
	}

	.ladies-audition >>> .el-tabs__nav-scroll {
		border-left: 1px solid rgb(228, 228, 228);
	}

	.ladies-audition >>> .el-tabs__nav {
		border: none;
	}

	.ladies-audition >>> .el-tabs--left .el-tabs__nav-wrap.is-left::after,
	.el-tabs--left .el-tabs__nav-wrap.is-right::after,
	.el-tabs--right .el-tabs__nav-wrap.is-left::after,
	.el-tabs--right .el-tabs__nav-wrap.is-right::after {
		width: 1px !important;
	}
</style>
