var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"auto","min-height":"100%","display":"flex","flex-direction":"column"},style:({
		height: `${this.pageHeight - 60}px`,
		width: `${this.pageWidth}px`,
	})},[_c('div',{staticClass:"guide-container-img",style:({
			backgroundImage: `url(${_vm.banner})`,
			backgroundSize: '100% 100%',
			backgroundRepeat: 'no-repeat',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: 'white',
			fontSize: '24px',
		})},[_vm._v(" 客服中心 ")]),_c('div',{staticClass:"content",staticStyle:{"padding":"20px"},style:({
			height: `${this.pageHeight - 310}px`,
		}),domProps:{"innerHTML":_vm._s(_vm.content)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }